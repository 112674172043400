
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import CheckBox from "@/components/common/CheckBox.vue";

export default defineComponent({
  name: "MemberQuit",
  components: {
    BaseHeader2,
    DefaultButton,
    CheckBox,
  },
  data() {
    const store = useStore();
    return {
      store,
      checkAgree: false,
    };
  },
  methods: {
    quit() {
      this.store.commit("main/setIsLoading", true);
      this.$flutter.callHandler("showToast", {
        message: "탈퇴처리가 완료되었습니다.",
      });
      this.$axios
        .post("member/quit")
        .then((result) => {
          this.store.commit("main/setIsLoading", false);
          this.store.dispatch("member/signOut").then((result) => {
            this.$injectToken(null);
            this.$flutter.callHandler("updateToken", {
              access: "",
              refresh: "",
            });
            this.$flutter.callHandler("selectGnb", {
              path: "/",
            });
          });
        })
        .catch(() => {
          this.store.commit("main/setIsLoading", false);
          this.$flutter.callHandler("showToast", {
            message: "에러가 발생했습니다. 관리자에게 문의해주세요.",
            color: "error",
          });
        });
    },
  },
});
